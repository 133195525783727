import { SwitchProps } from './switch.types';
import { Switch as MantineSwitch } from '@mantine/core';
import styles from './switch.module.scss';

export const Switch = ({ disabled, onChange, checked }: SwitchProps) => {
  return (
    <MantineSwitch
      disabled={disabled}
      size='lg'
      onChange={onChange}
      checked={checked}
      classNames={{
        thumb: styles.switch__thumb,
        track: styles.switch__track,
        input: styles.switch__input,
      }}
    />
  );
};
