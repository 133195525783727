import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery, getOrigin } from '@/shared/lib/base-query';
import { apiEndpoints } from '@/shared/constants/links';

export const mcqLetterApi = createApi({
  reducerPath: 'mcqLetterApi',
  baseQuery: baseQuery(getOrigin() + apiEndpoints.mobileCommunicationQuality),
  endpoints: (builder) => ({
    mcqLetter: builder.mutation({
      query: (data) => ({
        url: '/new',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { useMcqLetterMutation } = mcqLetterApi;