import React from 'react';
import { Tabs as MantineTabs } from '@mantine/core';
import { useMediaQuery } from '@/shared/lib/use-media-query';
import { Select } from '@/shared/ui-kit';
import styles from './tabs.module.scss';

interface Props {
  data: { id: number; name: string }[];
  activeTabValue: string;
  onChange: (value: string) => void;
  className?: string;
}

export const Tabs = ({ data, activeTabValue, onChange, className }: Props) => {
  const { isMobile } = useMediaQuery();

  return (
    <MantineTabs
      value={activeTabValue}
      onTabChange={onChange}
      className={className}
      classNames={{
        tabsList: styles.tabs__list,
        tab: styles.tabs__tab,
      }}
    >
      {isMobile ? (
        data && (
          <Select
            onChange={onChange}
            data={[
              ...data.map(({ name }) => ({
                label: name,
                value: name,
              })),
            ]}
            value={activeTabValue}
          />
        )
      ) : (
        <MantineTabs.List>
          {data?.map(({ id, name }) => (
            <MantineTabs.Tab value={name} key={id}>
              {name}
            </MantineTabs.Tab>
          ))}
        </MantineTabs.List>
      )}
    </MantineTabs>
  );
};
