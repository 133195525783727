import { useMediaQuery as useMediaQueryMantine } from '@mantine/hooks';
import { onlyMobile, tablet, tabletOnly, desktop, desktopMax, mobileBanner } from '../constants/media-queries';

export const useMediaQuery = () => {
  const isMobile = useMediaQueryMantine(onlyMobile);
  const isTablet = useMediaQueryMantine(`(min-width: ${tablet}px) and (max-width: ${tabletOnly}px)`);
  const isDesktop = useMediaQueryMantine(desktop);
  const isDesktopMax = useMediaQueryMantine(desktopMax);

  const isMobileBanner = useMediaQueryMantine(mobileBanner);

  return { isMobile, isTablet, isDesktop, isDesktopMax, isMobileBanner };
};
