import { usePathname } from 'next/navigation';
import { strapiCollectionResponseMapper } from '@/shared/lib/strapi-mappers';
import { PageMeta } from '@/shared/ui-kit';
import { useGetMetaQuery } from '@/store/api/meta/meta.api';

export const Meta = ( ) => {
  const pathname = usePathname();
  const { data } = useGetMetaQuery({ populate: 'meta_data' });
  const mappedData = strapiCollectionResponseMapper(data).sort((prev, next) => next.on_page.length - prev.on_page.length);

  const currentMeta = mappedData.find(({ on_page }) => {
    if (on_page.endsWith('/') && on_page.length > 1) {
      return on_page === pathname.slice(0, on_page.length);
    };

    return on_page === pathname;
  });

  return <PageMeta {...currentMeta?.meta_data} />;
};
