import React, { FC, memo, useState } from 'react';
import { CloseBtn } from '@/shared/assets/icons';
import { volnaTheme as theme } from '@/shared/constants/theme';
import cn from 'classnames';
import styles from './text-input.module.scss';
import { Input, InputProps } from '@mantine/core';
import { formatOnPaste, validate } from '@/shared/lib/validation';
import { ErrorIcon } from '@/shared/assets/svg';

interface TextInputProps extends InputProps {
  onChange?: (value: string) => void;
  darkBackground?: boolean;
  label?: string;
  placeholder?: string;
  initialValue?: string;
  value?: string;
  withValidation?: boolean;
  maxLength?: number;
  mask?: RegExp;
}

const TextInput: FC<TextInputProps> = memo(
  ({
    onChange: changeHandler,
    darkBackground,
    error,
    label,
    placeholder,
    initialValue,
    className,
    value: controlledValue,
    withValidation,
    mask,
    ...props
  }) => {
    const [value, setValue] = useState(initialValue || '');
    const [isActive, setIsActive] = useState(false);

    const onChange = (val: string) => {
      let newValue = val;
      if (withValidation) {
        if (value === val.slice(0, -1)) {
          newValue = validate(val) ? val : val.slice(0, -1);
        } else {
          newValue = formatOnPaste(val);
        }
      }
      changeHandler && changeHandler(newValue);
      setValue(newValue);
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (mask && !mask.test(e.key)) {
        e.preventDefault();
      }
    };

    return (
      <Input.Wrapper
        classNames={{
          root: cn(styles.wrapper__root, darkBackground && styles.dark, className && className),
          label: styles.wrapper__label,
          error: styles.wrapper__error,
        }}
        label={label}
        error={error}
      >
        <Input
          classNames={{
            input: styles.input__input,
            rightSection: styles.input__right_section,
            wrapper: styles.input__wrapper,
          }}
          error={error}
          value={controlledValue || value}
          placeholder={placeholder}
          onInput={(event) => onChange(event.currentTarget.value)}
          onFocus={() => setIsActive(true)}
          onBlur={() => setIsActive(false)}
          onFocusCapture={() => setIsActive(true)}
          onBlurCapture={() => setIsActive(false)}
          rightSectionWidth={50}
          onKeyPress={handleKeyPress} 
          rightSection={
            error ? (
              <ErrorIcon />
            ) : (
              <button
                type='button'
                onClick={() => onChange('')}
                className={cn(styles.closeBtn, { [styles.visible]: value && isActive })}
              >
                <CloseBtn color={darkBackground ? theme.colors.base[200] : theme.colors.base[400]} />
              </button>
            )
          }
          {...props}
        />
      </Input.Wrapper>
    );
  },
);

TextInput.displayName = 'TextInput';
export { TextInput };
