import styles from './tariff-page/home-internet.module.scss';
import cn from 'classnames';
import { BUILDING_TYPE } from '@/store/api/service-home-internet/service-home-internet.type';
import { ServiceItem } from '@/store/slice/home-internet.slice';

export enum HOME_INTERNET__CONSTANTS {
  TITLE = 'Домашний интернет',

  POSSIBLE = 'Есть возможность!',
  IMPOSSIBLE = 'Возможности пока нет (',

  ADDRESS_LABEL = 'Введите адрес',
  ADDRESS_PLACEHOLDER = 'Адрес подключения',

  PHONE_LABEL = 'Введите телефон',

  ADDRESS_DESCRIPTION_PART1 = 'Мы активно развиваемся и скоро будем у вас!',
  ADDRESS_DESCRIPTION_PART2 = 'Оставьте контактные данные, и мы сообщим вам о возможности подключения, как только она появится.',

  POSSIBLE__LABEL = 'Оставьте контактные данные, чтобы подключить интернет.',

  BUTTON_POSSIBLE = 'Подключить интернет',
  BUTTON_IMPOSSIBLE = 'Хочу интернет дома!',

  PRICE = 'от 300 ₽ /мес',

  SPEED = 'Скорость Интернета',
  PARAMETRS = 'Настроить параметры',
  CHANNEL = 'Пакет ТВ',
  ADDITIONAL = 'Дополнительные опции',

  SBERBOX = 'Аренда приставки SberBox',
  SBERBOX_PRICE = '150 ₽ /мес',
  SBERBOX_INFO = 'Простое подключение по Wi-Fi, онлайн-кинотеатры, музыка, фитнес-программы, детские каналы и облачный гейминг — все это вы получите благодаря умной ТВ-приставке SberBox!',
  WIFI = 'Аренда Wi-Fi усилителя',
  WIFI_PRICE = '150 ₽ /мeс',
  WIFI_INFO = 'Выбирайте любой из 3 усилителей сигнала и наслаждайтесь мощным покрытием Wi-Fi по всему дому! Бесплатная доставка и установка.',
  VIJU = 'Подписка viju: кино и сериалы',
  VIJU_PRICE = 'Месяц бесплатно, потом 180 ₽',
  VIJU_INFO = 'Тысячи HD фильмов и сериалов на любом устройстве без рекламы. Попробуйте бесплатно первый месяц, далее 180 ₽/месяц! Отказаться можно в любой момент.',

  SUBSCRIPTIONS = 'Подписки',

  ADD_BUTTON = 'Добавить к тарифу',
  ADDED_BUTTON = 'Добавлено',

  TV_BASKET_LABEL = 'интерактивного ТВ',

  MAX_MOBILE = 'mogu-pozvolity',
}

const HOUSE_LABEL = {
  [BUILDING_TYPE.APARTMENT]: 'Квартира',
  [BUILDING_TYPE.HOUSE]: 'Частный дом',
};

export const BuildingTypeData = [
  { value: String(BUILDING_TYPE.APARTMENT), label: HOUSE_LABEL[BUILDING_TYPE.APARTMENT] },
  { value: String(BUILDING_TYPE.HOUSE), label: HOUSE_LABEL[BUILDING_TYPE.HOUSE] },
];

export enum SLIDER_VALUE {
  VALUE_1 = 0,
  VALUE_2 = 50,
  VALUE_3 = 100,
}

const SPEED_LABELS = {
  [SLIDER_VALUE.VALUE_1]: '200 Мбит/с',
  [SLIDER_VALUE.VALUE_2]: '500 Мбит/с',
  [SLIDER_VALUE.VALUE_3]: '1 Гбит/с',
};

export const speedMarks = [
  {
    value: SLIDER_VALUE.VALUE_1,
    label: <div className={styles.marks__first}>{SPEED_LABELS[SLIDER_VALUE.VALUE_1]}</div>,
  },
  { value: SLIDER_VALUE.VALUE_2, label: SPEED_LABELS[SLIDER_VALUE.VALUE_2] },
  {
    value: SLIDER_VALUE.VALUE_3,
    label: <div className={styles.marks__last}>{SPEED_LABELS[SLIDER_VALUE.VALUE_3]}</div>,
  },
];

export const speedMapper = {
  [SLIDER_VALUE.VALUE_1]: {
    label: SPEED_LABELS[SLIDER_VALUE.VALUE_1],
    price: {
      apartment: 455,
      house: 595,
    },
  },
  [SLIDER_VALUE.VALUE_2]: {
    label: SPEED_LABELS[SLIDER_VALUE.VALUE_2],
    price: {
      apartment: 655,
      house: 795,
    },
  },
  [SLIDER_VALUE.VALUE_3]: {
    label: SPEED_LABELS[SLIDER_VALUE.VALUE_3],
    price: {
      apartment: 905,
      house: 995,
    },
  },
};

const TV_LABELS = {
  [SLIDER_VALUE.VALUE_1]: '100 каналов',
  [SLIDER_VALUE.VALUE_2]: '135 каналов',
  [SLIDER_VALUE.VALUE_3]: '150 каналов',
};

export const tvMarks = [
  { value: SLIDER_VALUE.VALUE_1, label: <div className={styles.marks__first}>{TV_LABELS[SLIDER_VALUE.VALUE_1]}</div> },
  { value: SLIDER_VALUE.VALUE_2, label: TV_LABELS[SLIDER_VALUE.VALUE_2] },
  { value: SLIDER_VALUE.VALUE_3, label: <div className={styles.marks__last}>{TV_LABELS[SLIDER_VALUE.VALUE_3]}</div> },
];

export const tvLabels = {
  [SLIDER_VALUE.VALUE_1]: 'Начальный',
  [SLIDER_VALUE.VALUE_2]: 'Популярный',
  [SLIDER_VALUE.VALUE_3]: 'Премиальный',
};

export const tvMapper = {
  [SLIDER_VALUE.VALUE_1]: { priceLabel: 'Начальный', price: 0, label: TV_LABELS[SLIDER_VALUE.VALUE_1] },
  [SLIDER_VALUE.VALUE_2]: { priceLabel: 'Популярный', price: 200, label: TV_LABELS[SLIDER_VALUE.VALUE_2] },
  [SLIDER_VALUE.VALUE_3]: { priceLabel: 'Премиальный', price: 350, label: TV_LABELS[SLIDER_VALUE.VALUE_3] },
};

export const marksMapper = (arr?: Array<ServiceItem>) =>
  arr?.map(({ localized_name, slider_value }, index) => ({
    value: slider_value,
    label: (
      <div className={cn({ [styles.marks__first]: index === 0 }, { [styles.marks__last]: index === arr.length - 1 })}>
        {localized_name}
      </div>
    ),
  }));

export const stepCounter = (arr?: Array<unknown>) => (arr ? 100 / (arr.length - 1) : 50);
