import { FC, useEffect, useState } from 'react';
import { Modal, ScrollArea } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Popup as PopupType } from '@/store/api/popup/popup.type';
import { useMediaQuery } from '@/shared/lib/use-media-query';
import { BottomSheet } from '@/shared/ui-kit/bottom-sheet/bottom-sheet';
import { Content } from './content';
import styles from './popup.module.scss';
import { toggleWidgetVisibility } from '@/shared/lib/chat2desk-utils';
import { PageMeta } from '@/shared/ui-kit';

interface PopupProps extends PopupType {
  popupIsOpened: boolean;
  setPopupIsOpened: (val: boolean) => void;
}

export const Popup: FC<PopupProps> = ({
  title,
  text,
  uid,
  meta_data,
  buttonLabel,
  buttonLink,
  buttonColor,
  delay=0,
  popupIsOpened,
  setPopupIsOpened
}) => {
  const [opened, { close, open }] = useDisclosure(false);
  const [timer, setTimer] = useState(-1);
  const { isMobile } = useMediaQuery();

  useEffect(() => {
    setTimer(delay || 0);
  }, [delay, uid]);

  useEffect(() => {
    if (opened) {
      toggleWidgetVisibility(false);
    };
  }, [opened]);

  useEffect(() => {
    if(!popupIsOpened) {
      if (timer > 0) {
        const interval = setTimeout(() => setTimer((timer: number) => timer - 1), 1000);
        
        return () => clearTimeout(interval);
      }
      if (timer === 0) {
        open();
        setPopupIsOpened(true);
      }      
    }
  }, [open, popupIsOpened, setPopupIsOpened, timer, uid]);

  const onClose = () => {
    close();
    localStorage.setItem(uid, 'true');
  };

  const content = (
    <>
      <PageMeta {...meta_data} />
      <Content
        title={title}
        text={text}
        buttonLabel={buttonLabel}
        buttonLink={buttonLink}
        buttonColor={buttonColor}
        onClose={onClose}
      />
    </>

  );

  return isMobile ? 
    (
      <BottomSheet 
        open={opened}
        onDismiss={onClose}
        className={styles.bottomsheet}
        maxHeight={550}
      >
        {content}
      </BottomSheet>
    ) : (
      <Modal
        opened={opened}
        onClose={onClose} 
        classNames={{
          content: styles.modal__content,
          header: styles.modal__header,
          body: styles.modal__body,
          close: styles.modal__close
        }}
        centered
        radius='24px'
      >
        <ScrollArea.Autosize
          w='calc(100% + 8px)'
          mah={'calc(100vh - 230px)'}
          type='auto'
          scrollbarSize='8px'
          offsetScrollbars
          classNames={{
            scrollbar: styles.scroll__bar,
            thumb: styles.scroll__thumb,
            viewport: styles.scroll__viewport,
          }}
        >
          {content}
        </ScrollArea.Autosize>
      </Modal>
    );
};
