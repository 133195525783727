import { configureStore } from '@reduxjs/toolkit';
import { articleApi } from './api/article/article.api';
import { tariffApi } from './api/tariff-mobile/tariff-mobile.api';
import { preCrmTicketApi } from './api/ticket/ticket.api';
import { orderProcessingApi } from './api/order/order.api';
import { aboutCompanyApi } from './api/about-company/about-company.api';
import { mobileAppApi } from './api/mobile-app/mobile-app.api';
import { mainPageApi } from './api/main-page/main-page.api';
import { serviceApi } from './api/service/service.api';
import { serviceCategoryApi } from './api/service-category/service-category';
import { AppSlice } from './slice/app.slice';
import { serviceSubcategoryApi } from './api/service-subcategory/service-subcategory';
import { salesPointsApi } from './api/sales-points/sales-points.api';
import { paymentApi } from './api/payment/payment.api';
import { HomeInternetSlice } from './slice/home-internet.slice';
import { tariffHomeInternetsApi } from './api/tariff-home-internet/tariff-home-internet.api';
import { tariffHomeInternetCategoriesApi } from './api/tariff-home-internet-category/tariff-home-internet-category.api';
import { MobileTariffSlice } from './slice/mobile-tariff.slice';
import { beautifulNumbers } from './api/beautiful-numbers/beautiful-numbers.api';
import { beautifulNumberSlice } from './slice/beautiful-number';
import { smsApi } from './api/sms-auth/sms-auth.api';
import { serviceHomeInternetApi } from './api/service-home-internet/service-home-internet.api';
import { faqApi } from './api/faq/faq.api';
import { faqCategoryApi } from './api/faq/faq.api';
import { landingApi } from './api/landing/landing.api';
import { tariffFeatureApi } from './api/tariff-feature/tariff-feature.api';
import { popupApi } from './api/popup/popup.api';
import { inviteFriendApi } from './api/invite-friend/invite-friend.api';
import { mcqLetterApi } from './api/mobile-communication-quality/mobile-communication-quality.api';
import { metaApi } from './api/meta/meta.api';

const store = configureStore({
  reducer: {
    app: AppSlice.reducer,
    homeInternet: HomeInternetSlice.reducer,
    mobileTariff: MobileTariffSlice.reducer,
    beautifulNumber: beautifulNumberSlice.reducer,
    [articleApi.reducerPath]: articleApi.reducer,
    [tariffApi.reducerPath]: tariffApi.reducer,
    [tariffHomeInternetsApi.reducerPath]: tariffHomeInternetsApi.reducer,
    [tariffHomeInternetCategoriesApi.reducerPath]: tariffHomeInternetCategoriesApi.reducer,
    [aboutCompanyApi.reducerPath]: aboutCompanyApi.reducer,
    [serviceApi.reducerPath]: serviceApi.reducer,
    [serviceCategoryApi.reducerPath]: serviceCategoryApi.reducer,
    [serviceSubcategoryApi.reducerPath]: serviceSubcategoryApi.reducer,
    [serviceHomeInternetApi.reducerPath]: serviceHomeInternetApi.reducer,
    [mainPageApi.reducerPath]: mainPageApi.reducer,
    [salesPointsApi.reducerPath]: salesPointsApi.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
    [orderProcessingApi.reducerPath]: orderProcessingApi.reducer,
    [preCrmTicketApi.reducerPath]: preCrmTicketApi.reducer,
    [beautifulNumbers.reducerPath]: beautifulNumbers.reducer,
    [smsApi.reducerPath]: smsApi.reducer,
    [faqApi.reducerPath]: faqApi.reducer,
    [faqCategoryApi.reducerPath]: faqCategoryApi.reducer,
    [landingApi.reducerPath]: landingApi.reducer,
    [mobileAppApi.reducerPath]: mobileAppApi.reducer,
    [tariffFeatureApi.reducerPath]: tariffFeatureApi.reducer,
    [popupApi.reducerPath]: popupApi.reducer,
    [inviteFriendApi.reducerPath]: inviteFriendApi.reducer,
    [mcqLetterApi.reducerPath]: mcqLetterApi.reducer,
    [metaApi.reducerPath]: metaApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat([
      articleApi.middleware,
      tariffApi.middleware,
      tariffHomeInternetsApi.middleware,
      tariffHomeInternetCategoriesApi.middleware,
      aboutCompanyApi.middleware,
      serviceApi.middleware,
      serviceCategoryApi.middleware,
      serviceSubcategoryApi.middleware,
      serviceHomeInternetApi.middleware,
      mainPageApi.middleware,
      salesPointsApi.middleware,
      paymentApi.middleware,
      orderProcessingApi.middleware,
      preCrmTicketApi.middleware,
      smsApi.middleware,
      beautifulNumbers.middleware,
      faqApi.middleware,
      faqCategoryApi.middleware,
      landingApi.middleware,
      mobileAppApi.middleware,
      tariffFeatureApi.middleware,
      popupApi.middleware,
      inviteFriendApi.middleware,
      mcqLetterApi.middleware,
      metaApi.middleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
