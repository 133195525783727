import { FC, ReactNode } from 'react';
import { Tooltip } from '@/shared/ui-kit';
import Info from '@/shared/assets/svg/info-small.svg';
import styles from './info-with-tooltip.module.scss';

interface InfoWithTooltipProps {
  info: string;
  description?: ReactNode;
}

export const InfoWithTooltip: FC<InfoWithTooltipProps> = ({ info, description }) => {
  return (
    <div className={styles.container}>
      <span className={styles.info}>{info}</span>
      {description ? (
        <Tooltip label={description} width={300} withinPortal={false}>
          <div className={styles.icon}>
            <Info />
          </div>
        </Tooltip>
      ) : null}
    </div>
  );
};
