import { addHours, format, differenceInMilliseconds } from 'date-fns';
import { ParsedUrlQuery } from 'querystring';
import { SalesPointEntity } from '@/store/api/sales-points/sales-points.type';
import { Point } from '@/shared/ui-kit/map/map.types';

export function btoa(input: string) {
  return Buffer.from(input, 'binary').toString('base64');
}

type IsJsonStringResult = {
  isValid: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
};

export function isJsonString(str: string) {
  const result: IsJsonStringResult = {
    isValid: false,
    object: null,
    error: null,
  };
  try {
    result.object = JSON.parse(str);
    result.isValid = true;
  } catch (e) {
    result.error = (e as Error).toString();
  }

  return result;
}

export function getShortString(str: string) {
  if (str && str.length && str.length > 255) {
    return str.substring(0, 255) + `...(${str.length - 255})`;
  }

  return str;
}

export function formatPrice(price?: number | string, isService: boolean = false) {
  if (price !== undefined) {
    if (price === 0 || price === '0') {
      return isService ? '0 ₽' : 'Бесплатно';
    } else {
      let numericPrice;
      if (typeof price === 'number') {
        numericPrice = price;
      } else {
        numericPrice = parseFloat(price);
      }

      if (isNaN(numericPrice)) {
        return '';
      }

      return `${numericPrice.toLocaleString('ru-RU')} ₽`;
    }
  }

  return '';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getDataSafe(data: any, fallbackData: any) {
  if (data?.status && data.status === 'fulfilled' && data?.value && !data.value.error) {
    return data.value;
  }

  return fallbackData;
}

export function formatDateToDDmonthYYYY(date?: Date | null) {
  if (!date) {
    return '';
  }

  return (
    date.toLocaleDateString('ru-RU', { day: 'numeric', month: 'long' }) +
    format(date, ' с HH:mm') +
    format(addHours(date, 1), ' до HH:mm')
  );
}

//функция для округления цифры до нуля, если заканчивается на 49 или 99
export const priceRounder = (num: number) =>
  String(num).slice(-2) === '49' || String(num).slice(-2) === '99' ? num + 1 : num;

export const salesPointMapper = (pointsRes?: SalesPointEntity): Point[] =>
  pointsRes
    ? pointsRes.data?.map(({ attributes, id }) => ({
      id,
      fullAddress: `${attributes?.settlement} ${attributes?.address}`,
      services: attributes?.sales_point_category?.data?.attributes?.services,
      ...attributes,
    }))
    : [];

export const isClient = () => {
  return typeof window !== 'undefined';
};

export function getRedirectDestination(routes: string[][], route: string) {
  for (let i = 0; i < routes.length; i++) {
    if (routes[i][0] && routes[i][0].includes(route)) {
      return routes[i][1];
    }
  }

  return null;
}

export function getQueryStringWithoutSlug(query: ParsedUrlQuery, slug = 'slug') {
  const queryParams = new URLSearchParams(query as Record<string, string>);
  queryParams.delete(slug);

  return queryParams.toString();
}

const getCurrentTimestampInSeconds = (): string => {
  const milliseconds = Date.now() % 1000;
  const timestamp = Math.floor(Date.now() / 1000);
  const suffix = milliseconds < 500 ? '0' : '5';
  return `${timestamp}${suffix}`;
};

export function formatTimeDifference(startDate: Date, endDate: Date): string {
  const diffInMilliseconds = differenceInMilliseconds(endDate, startDate);
  const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const remainingSeconds = diffInSeconds % 60;
  const remainingMilliseconds = diffInMilliseconds % 1000;

  return `${String(diffInMinutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}.${String(remainingMilliseconds).padStart(3, '0')}`;
}

export function decodeInviteCode(code: string): string {
  return atob(code);
}

export function isObject(value: unknown) {
  return value !== null && typeof value === 'object';
}
