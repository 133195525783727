import { FC } from 'react';
import Link from 'next/link';
import { Button } from '@mantine/core';
import { COLORS } from '@/shared/constants/colors';
import { getOrigin } from '@/shared/lib/base-query';
import { Popup } from '@/store/api/popup/popup.type';
import styles from './popup.module.scss';

interface ContentProps extends Pick<Popup, 'title' | 'text' | 'buttonLabel' | 'buttonLink' | 'buttonColor'> {
  onClose: () => void;
}

export const Content:FC<ContentProps> = ({ title, text, buttonLabel, buttonLink, buttonColor, onClose }) => {
  const newTab = !buttonLink?.startsWith('/') || !buttonLink?.includes(getOrigin());

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h4 className={styles.title}>{title}</h4>
        {text && <div className={styles.text} dangerouslySetInnerHTML={{ __html: text }} />}
      </div>
      <Button onClick={onClose} className={styles.button}
        style={{ backgroundColor: buttonColor || COLORS.volna.magenta[100] }}
      >
        {buttonLink && <Link href={buttonLink} className={styles.link} target={newTab ? '_blank' : '_self'} />}
        {buttonLabel || 'Понятно'}
      </Button>        
    </div>
  );
};
