import { useEffect, useMemo, useState } from 'react';
import { usePathname } from 'next/navigation';
import { strapiCollectionResponseMapper } from '@/shared/lib/strapi-mappers';
import { useGetPopupsQuery } from '@/store/api/popup/popup.api';
import { Popup } from './content';

export const Popups = () => {
  const pathname = usePathname();
  const { data } = useGetPopupsQuery({ populate: 'meta_data', sort: 'priority' });
  const mappedData = strapiCollectionResponseMapper(data);
  const [popupIsOpened, setPopupIsOpened] = useState(false);

  useEffect(() => {
    setPopupIsOpened(false);
  }, [pathname]);

  const currentData = useMemo(() => mappedData.find(({ onPage, showOnEveryPage, uid }) => {
    const onMainPageOnly = onPage === '/' || !onPage;
    const isMainPage = pathname === '/';
    const isSection = onPage?.endsWith('/');
    const includesInPathname = onPage && (pathname.startsWith(onPage) || isSection && pathname.startsWith(onPage.slice(0, -1)));
    const onThisPage = showOnEveryPage || includesInPathname || (isMainPage && onMainPageOnly);
    const hasShown = localStorage.getItem(uid);

    return onThisPage && !hasShown;
  }), [mappedData, pathname]);

  return currentData ? (
    <Popup popupIsOpened={popupIsOpened} setPopupIsOpened={setPopupIsOpened} {...currentData} />
  ): null;
};
