import React, { FC } from 'react';
import { Progress, ProgressProps } from '@mantine/core';
import styles from './progress-bar.module.scss';

export const ProgressBar: FC<ProgressProps> = ({ value }) => {

  return (
    <Progress radius="xl" size="xs" value={value} classNames={{ root: styles.root, bar: styles.bar }} />
  );
};
