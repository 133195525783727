import { memo } from 'react';
import { usePathname } from 'next/navigation';
import cn from 'classnames';
import { Logo_3 } from '@/shared/assets/svg';
import { useMediaQuery } from '@/shared/lib/use-media-query';
import { Path } from '@/shared/constants/links';
import Link from '@/shared/ui-kit/link/link';
import styles from './footer.module.scss';

export const Footer = memo(() => {
  const pathname = usePathname();
  const { isMobile } = useMediaQuery();
  const currentYear = new Date().getFullYear();
  const withBasketPadding = [Path.tariffMobile.root, Path.tariffHomeInternet.root].some((val) => pathname.startsWith(val));

  return (
    <div className={cn(styles.wrapper, { [styles.wrapper__tariff]: withBasketPadding })}>
      <div className={styles.leftSide}>
        <div className={styles.logo}>
          <Logo_3 />
        </div>
        <div className={styles.tagline}>
          <div className={styles.yourConnection}>Твоя связь.</div>
          <div className={styles.yourCrimea}>Твой Крым</div>
        </div>
      </div>
      <div className={styles.supportService}>
        <span className={styles.support}>Служба поддержки</span>
        <Link href='tel:+79785550555' className={styles.phoneNumber}>
          +7(978) 555-0-555
        </Link>
      </div>
      {!isMobile && <div className={styles.telecom2023}>© ООО «КТК Телеком», {currentYear}</div>}
    </div>
  );
});

Footer.displayName = 'Footer';
