import { FC, useEffect } from 'react';
import { Portal } from '@mantine/core';
import cn from 'classnames';
import styles from './notification.module.scss';

interface NotificationProps {
  label: string;
  onClose: () => void;
  opened: boolean;
  closeDelay?: number;
}

export const Notification:FC<NotificationProps> = ({ opened, onClose, closeDelay, label }) => {
  useEffect(() => {
    opened && setTimeout(() => onClose(), closeDelay || 3000);
  }, [closeDelay, onClose, opened]);

  return (
    <Portal className={cn(styles.portal, { [styles.portal__none]: !opened })}>
      <div className={styles.content}>{label}</div>
    </Portal>
  );
};
