import { FC } from 'react';
import { Modal } from '@/shared/ui-kit';
import { Button } from '@mantine/core';
import { useMediaQuery } from '@/shared/lib/use-media-query';
import { BottomSheet } from '@/shared/ui-kit/bottom-sheet/bottom-sheet';
import styles from './sending-data-modal.module.scss';

export type SendingDataProps = {
  opened: boolean;
  close: () => void;
  isSuccess: boolean;
  isError: boolean;
  send: () => void;
};

export const SendingDataModal: FC<SendingDataProps> = ({ opened, close, isSuccess, send }) => {
  const { isMobile } = useMediaQuery();
  const title = isSuccess ? 'Спасибо за участие!' : 'Ошибка';
  const infoText = isSuccess ?
    'Ваша помощь бесценна 🤗 Свяжемся с вами, как только появится новая информация по этой проблеме.' :
    'К сожалению, произошла ошибка 😢 Попробуйте отправить ещё раз';

  const content = (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.title}>{title}</div>
        {/* <div className={styles.info}>Номер заявки: <span>1</span></div> //бэк нам ничего не возвращает */}
      </div>
      {infoText}
      {isSuccess ? (
        <Button onClick={close} className={styles.button}>
          Понятно
        </Button>
      ) : (
        <Button onClick={send} className={styles.button}>
          Отправить
        </Button>
      )}
    </div>
  );

  return isMobile ? (
    <BottomSheet open={opened} onDismiss={close} className={styles.bottomsheet}>
      {content}
    </BottomSheet>
  ) : (
    <Modal opened={opened} onClose={close} contentClassName={styles.modal} className={styles.closeButton}>
      {content}
    </Modal>
  );
};
