import { FC, useEffect, useState } from 'react';
import { SegmentedControl, SegmentedControlProps } from '@mantine/core';
import cn from 'classnames';
import styles from './pills.module.scss';

export const Pills: FC<SegmentedControlProps> = ({ data, disabled, value, ...props }) => {
  const [safeValue, setSafeValue] = useState(value);
  const typeOfData = typeof data?.[0];
  const isDataSafe = data.every((item) => typeof item === typeOfData);

  useEffect(() => {
    const doesValueForPillsExist = data?.find((item) => {
      if(typeof item !== 'string') return item?.value === value;

      return item === value;
    });

    if(doesValueForPillsExist) {
      setSafeValue(value);
    } else {
      if (typeof data?.[0] === 'string') {
        setSafeValue(data[0]);
      } else {
        setSafeValue(data?.[0].value);
      }
    }
  }, [data, value]);

  if(!data || !data.length || !isDataSafe) return null;

  return (
    <SegmentedControl
      data={data}
      disabled={disabled}
      classNames={{
        root: cn(styles.root, disabled && styles.disabled),
        label: styles.label,
        control: styles.control,
        indicator: styles.indicator,
      }}
      value={safeValue}
      {...props}
    />
  );
};
