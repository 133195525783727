import { formatPhoneNumber } from '@/shared/lib/format-phone-number';
import { storage } from '@/shared/lib/storage';
import { questions, storageKey } from './constants';
import {
  Answer,
  StepId,
  QuestionPage,
  Question,
  AnswersTree,
} from './mobile-communication-quality.types';

export type StepsTree = Record<string, QuestionPage>

export const getStepsTree = () => {
  const result: StepsTree = {};

  for (let i = 0; i < questions.length; i++) {
    const id = questions[i].id;
    result[id] = questions[i];
  }

  return result;
};

export const findQuestion = (questions: Question[] | undefined, id: string) => {
  const question = questions?.find(q => q.id === id);

  return question;
};

export const getNextStepId = (stepData: QuestionPage, formValues: AnswersTree): string => {
  const { questions, nextId } = stepData;

  if (typeof nextId === 'string') return nextId;

  const [forkKey] = Object.keys(nextId as StepId); // это указатель на шаг, где есть развилка
  const values = Object.values(formValues[forkKey]); // это выбранные значения на этом шаге
  const nextStepMap = (nextId as StepId)[forkKey];
  const priorityQuestion = questions.find(q => q.hasPriority && values.includes(q.id));
  // Если есть вопрос с приоритетом и он выбран в values, возвращаем его ключ
  if (priorityQuestion && nextStepMap[priorityQuestion.id]) {
    return nextStepMap[priorityQuestion.id];
  }

  // Возвращаем первый соответствующий ключ, если есть совпадение
  const matchedKey = values.find(value => nextStepMap[value as string]);

  return matchedKey ? nextStepMap[matchedKey as string] : 'unknown';
};

export const getPrevStepId = (stepData: QuestionPage, formValues: AnswersTree): string => {
  const { prevId } = stepData;

  if (typeof prevId === 'string') return prevId;

  const [forkKey] = Object.keys(prevId as StepId); // это указатель на шаг, где была развилка
  const values = Object.values(formValues[forkKey]); // это выбранные значения на этом шаге
  const prevStepMap = (prevId as StepId)[forkKey];
  // Возвращаем первый соответствующий ключ, если есть совпадение
  const matchedKey = values.find(value => prevStepMap[value as string]);

  return matchedKey ? prevStepMap[matchedKey as string] : 'unknown';
};

export const findSelectedOption = (question: Question, id: string) => {
  const option = question?.options?.find(o => o.id == id);

  return option;
};

export const getStepNumber = (step: string) => {
  return parseInt(step.split('_')[0], 10);
};

export const getStepNumberForHTML = (step: string) => {
  const split = step.split('_');

  if (split[0] === '0') {
    return '';
  } else if (split[0] === '3' || split[0] === '4') {
    return split[0];
  }

  return split[0] ?
    `${split[0]}${split[1] ? '.1' : ''}.` :
    '';
};

export const formatAnswers = (formValues: AnswersTree, stepsTree: StepsTree) => {
  let result = '';

  Object.entries(formValues).sort((a, b) => {
    return getStepNumber(a[0]) < getStepNumber(b[0]) ? -1 : 1;
  }).forEach(([step, answers]) => {
    const stepData = stepsTree[step];
    const stepNumber = getStepNumberForHTML(stepData.id);
    result = result + `<b>${stepNumber ? stepNumber + ' ' : ''}${stepData.title}</b><br />`;
    // result = result + `${stepData.id} ${stepData.title}\n\n`;

    const entries = Object.entries(answers);
    for (let i = 0; i < entries.length; i++) {
      const [key, answer] = entries[i];
      const question = findQuestion(stepData.questions, key);
      if (question?.type === 'phoneInput') {
        result = result + `${question?.label}<br />${formatPhoneNumber(answer as string)}<br /><br />`;
        // result = result + `${question?.label}\n- ${formatPhoneNumber(answer as string)}\n`;
      } else if (question?.type === 'select') {
        const option = findSelectedOption(question, answer as string);
        result = result + `${question?.label}<br />- ${option?.value}<br /><br />`;
        // result = result + `${question?.label}\n- ${option?.value}\n`;
      } else if (question?.type === 'radioButton') {
        const option = findSelectedOption(question, answer as string);
        result = result + `- ${option?.value}<br /><br />`;
        // result = result + `- ${option?.value}\n`;
      } else if (question?.type === 'checkbox' && answer) {
        result = result + `- ${question?.text}<br />`;
        if (i === entries.length - 1) {
          result = result + '<br />';
        }
        // result = result + `- ${question?.text}\n`;
      } else if (question?.label && question?.labelForHTML !== null && answer) {
        result = result + `${question?.label}<br />- ${answer}<br /><br />`;
        // result = result + `${question?.label}\n- ${answer} \n`;
      } else if (answer) {
        result = result + `- ${answer}<br /><br />`;
        // result = result + `- ${answer} \n`;
      }
    }
  });

  return result;
};

export const getFormValuesFromStorage = () => {
  const initialFormState = storage.get(storageKey);

  if (initialFormState) {
    try {
      const parsedFormState = JSON.parse(initialFormState);

      return parsedFormState;
    } catch (error) {
      console.error(error);

      return {};
    }
  }

  return {};
};
