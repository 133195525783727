import React, { FC, useEffect, useState, useCallback } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { Button, TextInput, Checkbox, Textarea, ScrollArea, Radio, Accordion, Box, LoadingOverlay } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import cn from 'classnames';
import { useAppSelector } from '@/store/hooks';
import { LINKS } from '@/shared/constants/links';
import { SmsPanel } from '@/shared/ui-kit/form';
import { useMcqLetterMutation } from '@/store/api/mobile-communication-quality/mobile-communication-quality.api';
import { useSendSmsMutation } from '@/store/api/sms-auth/sms-auth.api';
import { Modal, PhoneInput, RichText, Select, } from '@/shared/ui-kit';
import { ArrowLeft, ArrowLeftWhite, CloseIcon } from '@/shared/assets/svg';
import { ProgressBar } from '@/shared/ui-kit/progress-bar/progress-bar';
import { Map } from '@/shared/ui-kit/map';
import { toggleWidgetVisibility } from '@/shared/lib/chat2desk-utils';
import { useMediaQuery } from '@/shared/lib/use-media-query';
import { storage } from '@/shared/lib/storage';
import { isObject } from '@/shared/lib/utils';
import {
  Answer,
  AnswersTree,
  Question,
} from '@/modules/mobile-communication-quality/mobile-communication-quality.types';
import { SendingDataModal } from './sending-data-modal/sending-data-modal';
import {
  formatAnswers, getFormValuesFromStorage,
  getNextStepId,
  getPrevStepId,
  getStepNumber,
  getStepsTree,
} from '@/modules/mobile-communication-quality/mobile-communication-quality.utils';
import { storageKey } from '@/modules/mobile-communication-quality/constants';
import styles from './quality-modal.module.scss';

interface ConnectionQualityModalProps {
  showModal: boolean;
  handleCloseModal: () => void;
}

const TOTAL_STEPS = 11;

export const ConnectionQualityModal: FC<ConnectionQualityModalProps> = ({ showModal, handleCloseModal }) => {
  const { isMobile, isDesktop } = useMediaQuery();
  const isSmsLoading = useAppSelector((state) => state.app.isSmsLoading);
  const [sendSms] = useSendSmsMutation();
  const [createLetter, { data: createLetterData, isLoading, isError, isSuccess, reset }] = useMcqLetterMutation();
  const [activeButton, setActiveButton] = useState(false);
  const [step, setStep] = useState('0'); // Текущий шаг
  const stepNumber = getStepNumber(step);
  const stepsTree = getStepsTree();
  const stepData = stepsTree[step]; // Данные текущего шага
  const progress = (100 / TOTAL_STEPS) * stepNumber;
  const startPage = stepData?.id === '0';
  const [isAnimating, setIsAnimating] = useState(false);
  const stepsDifferentStyle = ['0', '1', '6', '11'];
  const rowStyles = stepsDifferentStyle.includes(step) ? styles.row : null;
  const initialFormState = getFormValuesFromStorage();
  const [formValues, setFormValues] = useState<AnswersTree>(initialFormState);
  const smsCodeScreen = stepData?.hasSMSCode;
  const [isSmsPanelDisabled, setIsSmsPanelDisabled] = useState(true);
  const [isDataModalOpened, { open, close }] = useDisclosure(false);

  const handleChangeFormData = (question: Question, value: Answer) => {
    setFormValues((prevValues) => {
      let value_ = value;
      if (question.type === 'checkbox') {
        value_ = value ? question.id : false;
      }

      const newState = {
        ...prevValues,
        [step]: {
          ...(question.type !== 'radioButton' ? prevValues[step] : {}),
          [question.id]: value_,
        },
      };
      const stateStringified = JSON.stringify(newState);
      storage.set(storageKey, stateStringified);

      return newState;
    });
  };

  const getInputValue = useCallback((id: string, arbitraryStep?: string) => {
    const currentStepData = formValues[arbitraryStep ? arbitraryStep : step];

    return isObject(currentStepData) ? currentStepData[id] : undefined;
  }, [formValues, step]);

  const isNonEmptyString = (value?: Answer) => typeof value === 'string' && value.length > 0;

  const handleSetActiveButton = useCallback(() => {
    const stepsConfig: Record<string, () => boolean> = {
      '1': () => {
        const phoneModel = getInputValue('1_2');

        return Boolean(getInputValue('1_1')) || isNonEmptyString(phoneModel);
      },
      '4_1': () => isNonEmptyString(getInputValue('4_1_1')),
      '6': () => isNonEmptyString(getInputValue('6_1')),
      '8_5': () => isNonEmptyString(getInputValue('8_5_1')),
      '9': () => isNonEmptyString(getInputValue('9_1')),
      '10_5': () => isNonEmptyString(getInputValue('10_5_1')),
      '11': () => true,
    };

    if (startPage) {
      const phoneNumber = getInputValue('0_2');
      const isStartPageReady = isNonEmptyString(getInputValue('0_1')) &&
        isNonEmptyString(phoneNumber) && (phoneNumber as string).length === 10;
      setActiveButton(isStartPageReady);
    } else if (smsCodeScreen) {
      setActiveButton(!isSmsPanelDisabled);
    } else if (stepsConfig[step]) {
      setActiveButton(stepsConfig[step]());
    } else {
      const isRadioSelected = stepData.questions.some(
        ({ type, id }) => type === 'radioButton' && getInputValue(id)
      );
      const isCheckboxSelected = stepData.questions.some(
        ({ type, id }) => type === 'checkbox' && getInputValue(id)
      );

      setActiveButton(isRadioSelected || isCheckboxSelected);
    }
  }, [getInputValue, isSmsPanelDisabled, smsCodeScreen, startPage, step, stepData.questions]);

  const handleStepForward = () => {
    const contactPhoneFromStorage = storage.get('contact_phone');
    const parsedPhoneNumber = contactPhoneFromStorage && JSON.parse(contactPhoneFromStorage);
    const isNotSamePhone = parsedPhoneNumber && parsedPhoneNumber?.contact_phone === getInputValue('0_2') as string;
    const timeIsExpired = parsedPhoneNumber && parsedPhoneNumber?.expirationTime > Date.now();

    if (startPage) {
      if (!contactPhoneFromStorage || isNotSamePhone || timeIsExpired) {
        sendSms({ contact_phone: getInputValue('0_2') as string, source_id: 1 });
        const sessionObject = {
          contact_phone: getInputValue('0_2') as string,
          expirationTime: Date.now() + 95000,
        };
        storage.set('contact_phone', JSON.stringify(sessionObject));
      }
    }

    if (stepData.nextId) {
      const nextStep = getNextStepId(stepData, formValues);
      if (nextStep && nextStep !== 'unknown') {
        setStep(nextStep);
      }
    }
    setIsAnimating(true);
  };

  const handleStepBackward = () => {
    if (stepData.prevId) {
      const prevStep = getPrevStepId(stepData, formValues);
      if (prevStep && prevStep !== 'unknown') {
        setStep(prevStep);
      }
    }
    setIsAnimating(true);
  };

  const handleCloseSendingDataModal = () => {
    reset();
    setStep('0');
    storage.remove(storageKey);
    close();
  };

  const handleSubmit = async () => {
    const formattedAnswers = formatAnswers(formValues, stepsTree);

    await createLetter({
      subject: 'Качество мобильной связи',
      text: '',
      html: formattedAnswers,
    }).unwrap();
  };

  // TODO подставить правильную ссылку
  const agree = (
    <div className={styles.agree}>
      Нажимая «Начать опрос», вы принимаете
      <Link href={LINKS.AGREEMENT} target='_blank'> условия обработки персональных данных</Link>
    </div>
  );

  const top = (
    <div>
      <h1 className={styles.title}>Качество мобильной связи</h1>
      <div className={styles.header}>
        {!startPage && !smsCodeScreen && <p>Вопрос {stepNumber} из {TOTAL_STEPS}</p>}
        <h3>{smsCodeScreen && <ArrowLeft onClick={handleStepBackward} />}{stepData?.title}</h3>
        {stepData?.subtitle && !smsCodeScreen && <RichText className={styles.markdown}>{stepData?.subtitle}</RichText>}
      </div>
    </div>
  );

  const footer = (
    <div className={styles.bottom}>
      {!startPage && !smsCodeScreen && <ProgressBar value={progress} />}

      <div className={cn(styles.butonnsGroup, { [styles.buttonsCol]: startPage && smsCodeScreen && !isDesktop })}>
        {stepData?.hasPrevButton && (
          <Button
            classNames={{
              root: cn(styles.button__root),
            }}
            onClick={handleStepBackward}
            disabled={step === '0'}>
            {stepData?.prevButtonLabel}
          </Button>
        )}

        {stepData?.hasNextButton && (
          <Button
            classNames={{
              root: cn(styles.button__root, { [styles.button__active]: activeButton }),
              icon: styles.flipIcon,
            }}
            rightIcon={activeButton && !smsCodeScreen && !startPage && <ArrowLeftWhite />}
            onClick={handleStepForward}
            disabled={!activeButton || isSmsLoading}>
            {isLoading || isSmsLoading ? (
              <div className={styles.loading}>
                <LoadingOverlay visible loaderProps={{ color: 'white' }} />
              </div>
            ) : (
              stepData?.nextButtonLabel
            )}
          </Button>
        )}

        {!startPage && !stepData?.hasNextButton && (
          <Button
            classNames={{
              root: cn(styles.button__root, { [styles.button__active]: activeButton }),
            }}
            onClick={handleSubmit}
            disabled={isLoading}>
            {!isLoading ? 'Отправить' : (
              <div className={styles.loading}>
                <LoadingOverlay visible loaderProps={{ color: 'white' }} />
              </div>
            )}
          </Button>
        )}

        {smsCodeScreen && agree}
      </div>
    </div>
  );

  const content = (
    <div className={cn(styles.content, { [styles.emergenceContent]: isAnimating })}>

      <div className={cn(styles.col, { [styles.col__withAccordion]: stepData?.instructionSteps })}>
        {stepData?.instructionSteps && (
          <Accordion
            transitionDuration={700}
            variant='contained'
            radius='lg'
            classNames={{
              item: styles.accordion__item,
              control: styles.accordion__control,
              chevron: styles.accordion__chevron,
              content: styles.accordion__content,
            }}
          >
            <Accordion.Item value={'instruction'} key={11}>
              <Accordion.Control>
                <h3>Инструкция</h3>
              </Accordion.Control>

              {stepData?.instructionSteps?.map(({ id, label, image }) => {
                return (
                  <Accordion.Panel key={id}>
                    <div className={styles.instruction}>
                      <div className={styles.instruction__row}>{id}.
                        <RichText className={styles.markdown}>{label}</RichText>
                      </div>
                      {image &&
                        <Image
                          className={styles.instruction__image}
                          src={image}
                          alt={'img'}
                          width={335}
                          height={144}
                          unoptimized
                        />
                      }
                    </div>
                  </Accordion.Panel>
                );
              })}
            </Accordion.Item>
          </Accordion>
        )}

        <div className={cn(
          styles.questions, {
            [styles.row]: rowStyles, [styles.questions__hasMap]: stepData?.hasMap, [styles.questions__select]: step === '1'
          }
        )}>
          {stepData?.questions?.map((question) => {
            const { id,
              type,
              image,
              label,
              options,
              placeholder,
              text,
              hideLabel,
            } = question;

            return (
              <div key={id} className={styles.questions__col}>

                {type === 'select' && options && Array.isArray(options) ? (
                  <div className={styles.questions__withOr}>
                    <Select
                      data={[
                        ...options.map(({ value, id }) => ({
                          label: value,
                          value: `${id}`,
                        })),
                      ]}
                      value={getInputValue(id) as string | undefined}
                      placeholder={placeholder}
                      onChange={(val) => handleChangeFormData(question, val as string)}
                      searchable
                      className={styles.select}
                    />
                    {stepData?.hasOrWord && (
                      <div className={styles.or}>или</div>
                    )}
                  </div>
                ) :
                  null
                }

                {type === 'radioButton' && options && Array.isArray(options) ? (
                  <>
                    <Radio.Group
                      className={styles.radio}
                      value={getInputValue(id) as string | undefined}
                      onChange={(e) => handleChangeFormData(question, e)}
                    >
                      {options.map((o, index) => (
                        <Radio
                          key={index}
                          value={o.id}
                          label={o.value}
                          classNames={{ root: styles.radio__root }}
                        />
                      ))}
                    </Radio.Group>

                  </>
                ) :
                  null
                }

                {type === 'checkbox' && (
                  <Checkbox
                    value={id}
                    label={text}
                    className={styles.checkbox}
                    checked={Boolean(getInputValue(id) as boolean | undefined)}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      handleChangeFormData(question, checked);
                    }}
                  />
                )}

                {type === 'textInput' && (
                  <TextInput
                    classNames={{
                      root: cn(styles.input, { [styles.input__network]: step === '6' }),
                      input: styles.input__input,
                      label: styles.input__label,
                    }}
                    label={label && !hideLabel ? label : null}
                    placeholder={placeholder}
                    value={getInputValue(id) as string | undefined || ''}
                    onChange={(e) => handleChangeFormData(question, e.target.value)}
                  />
                )}

                {type === 'phoneInput' && (
                  <PhoneInput
                    className={styles.input}
                    label={label}
                    placeholder={placeholder}
                    value={getInputValue(id) as string}
                    onChange={(e) => handleChangeFormData(question, e)} />
                )}

                {type === 'textarea' &&
                  <Textarea
                    placeholder={placeholder}
                    className={styles.textarea}
                    value={getInputValue(id) as string | undefined}
                    onChange={(e) => handleChangeFormData(question, e.target.value)} />}

                {image && (
                  <div className={styles.image}>
                    <Image
                      className={styles.mainImage}
                      src={image}
                      alt={'img'}
                      width={335}
                      height={144}
                      unoptimized
                    />
                  </div>
                )}
              </div>
            )}
          )}
        </div>

        {stepData?.hasMap && <Map className={cn(styles.map)} />}

        {stepData?.hasSMSCode && (
          <SmsPanel
            contact_phone={getInputValue('0_2', '0') as string}
            opened={smsCodeScreen}
            disabled={isSmsPanelDisabled}
            setDisabled={setIsSmsPanelDisabled}
            isMobileQualityModal={true}
          />
        )}
      </div>
    </div>
  );

  useEffect(() => {
    if (isAnimating) {
      setTimeout(() => {
        setIsAnimating(false);
      }, 600);
    }

  }, [isAnimating]);

  useEffect(() => {
    handleSetActiveButton();
  }, [
    startPage,
    stepData,
    step,
    activeButton,
    smsCodeScreen,
    isSmsPanelDisabled,
    isSmsLoading,
    formValues,
    getInputValue,
    handleSetActiveButton,
  ]);

  useEffect(() => {
    if (showModal) {
      toggleWidgetVisibility(false);
    } else {
      toggleWidgetVisibility(true);
    }
  }, [showModal]);

  useEffect(() => {
    if ((createLetterData && createLetterData?.success) || isError) {
      handleCloseModal();
      open();
    }
  }, [handleCloseModal, createLetterData, isError, open]);

  return (
    <>
      <Modal
        opened={showModal}
        onClose={handleCloseModal}
        centered
        classNames={{
          root: styles.modal__root,
          inner: styles.modal__inner,
          header: styles.modal__header,
          body: styles.modal__body,
          content: styles.modal__content,
        }}
      >
        <Box
          component='form'
          className={cn(styles.container)}
        >
          <div>
            <CloseIcon className={styles.close} onClick={handleCloseModal} />
            {top}
            <ScrollArea.Autosize
              w='calc(100% + 8px)'
              /*mah={isMobile ? 'calc(100vh - 180px)' : 'calc(100vh - 258px)'}*/
              mah={isMobile ? 'calc(100vh - 180px)' : '500px'}
              type='auto'
              scrollbarSize='8px'
              offsetScrollbars
              classNames={{
                scrollbar: styles.scroll__bar,
                thumb: styles.scroll__thumb,
                viewport: styles.scroll__viewport,
              }}
            >
              {content}
            </ScrollArea.Autosize>
          </div>
          {footer}
        </Box>
      </Modal>

      {isDataModalOpened &&
        <SendingDataModal
          opened={isDataModalOpened}
          close={handleCloseSendingDataModal}
          isSuccess={isSuccess}
          isError={isError}
          send={handleSubmit}
        />
      }
    </>
  );
};

// TODO Починить все переходы между шагами ✅ - готово, но надо перепроверить
// TODO Сделать правильный возврат на шаг назад в зависимости от того, по какой ветке пошли ✅ - готово, но надо перепроверить
// TODO Добавить пропущенный шаг 9.5 (слева) и сделать правильный возврат на шаг назад ✅ - готово, но надо перепроверить
// TODO Сделать подготовку текста письма ✅ - готово, но надо перепроверить
// TODO сделать сохранение данных формы в storage и заполнение полей данными из storage ✅ - готово
// TODO починить стили (отступ) радиобаттонов ✅ - готово
// TODO починить ссылку Условия обработки ПД
