import { createApi } from '@reduxjs/toolkit/query/react';
import { BeautifulNumber, NumberCategory } from './beautiful-numbers.type';
import { StrapiQueryParams } from '@/shared/types/strapi.types';
import { baseQuery, getOrigin } from '@/shared/lib/base-query';
import { apiEndpoints } from '@/shared/constants/links';
import { addingParameters } from '@/shared/lib/adding-parameters';
import { ORDER_PARAMS } from '@/modules/tariff/shared/requests/constants';

export const beautifulNumbers = createApi({
  reducerPath: 'beautifulNumbers',
  baseQuery: baseQuery(getOrigin() + apiEndpoints.orderProcessingService),
  endpoints: (builder) => ({
    getNumberCategories: builder.query<NumberCategory[], StrapiQueryParams>({
      query: () => {
        const queryParams = addingParameters({ params: { source_id: ORDER_PARAMS.SOURCE_ID } });

        return '/orders/get_number_categories' + queryParams;
      },
    }),
    getBeautifulNumbers: builder.query<Record<string, BeautifulNumber[]>, StrapiQueryParams>({
      query: (params) => {
        const queryParams = addingParameters({
          params: {
            limit: 100,
            last_id: 1,
            source_id: ORDER_PARAMS.SOURCE_ID,
            ...params,
          },
        });

        return '/orders/get_available_numbers' + queryParams;
      },
    }),
  }),
});

export const { useGetNumberCategoriesQuery, useGetBeautifulNumbersQuery } = beautifulNumbers;
