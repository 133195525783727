export const formatPhoneNumber = (phoneNumber?: string, shorten = true) => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  // Проверяем, соответствует ли очищенный номер формату из 10 цифр
  if (cleaned.length === 10) {
    // Форматируем номер, если он соответствует ожидаемому шаблону
    return `+7${shorten ? '' : ' '}(${cleaned.substring(0, 3)})${shorten ? '' : ' '}` +
      `${cleaned.substring(3, 6)}-${cleaned.substring(6, 8)}-${cleaned.substring(8, 10)}`;
  } else {
    return phoneNumber;
  }
};
