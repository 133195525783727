import { FC } from 'react';
import Head from 'next/head';

interface PageMetaProps {
  title?: string | null;
  description?: string | null;
}

export const PageMeta: FC<PageMetaProps> = ({ title, description }) => {
  return (
    <Head>
      {title && <title>{title}</title>}
      {description && <meta name='description' content={description} />}
    </Head>
  );
};
