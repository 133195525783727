import { createApi } from '@reduxjs/toolkit/query/react';
import { apiEndpoints } from '@/shared/constants/links';
import { baseQuery, getOrigin } from '@/shared/lib/base-query';
import { addingParameters } from '@/shared/lib/adding-parameters';
import { StrapiQueryParams } from '@/shared/types/strapi.types';
import { MetaEntity } from './meta.type';

export const metaApi = createApi({
  reducerPath: 'metaApi',
  baseQuery: baseQuery(getOrigin() + apiEndpoints.meta),

  endpoints: (builder) => ({
    getMeta: builder.query<MetaEntity, StrapiQueryParams>({
      query: (params) => addingParameters({ params }),
    }),
  }),
});

export const { useGetMetaQuery } = metaApi;
